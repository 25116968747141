// import React, { Component } from 'react';
// // import './src/App.css';
// import Navbar from 'react-bootstrap/Navbar';
// import NavLink from 'react-bootstrap/NavLink';
// import Nav from 'react-bootstrap/Nav';

// export default class NavBar extends Component {

//     render() {
//         return (
//             <Navbar fixed="fixed" bg="light" variant="light">
//                 <Navbar.Brand href="/" exact>XXX</Navbar.Brand>
//                 <Nav className="mr-auto" ></Nav>
//                 <NavLink href="/writing" exact>Articles</NavLink>
//                 <NavLink href="/about" exact >About</NavLink>
//             </Navbar>
//         )
//     }
// }